<template>
  <div class="wrapper"
       v-if="details.goodsList">
    <!-- 选择退款商品 -->
    <div style="">

    </div>
    <div class="main">
      <div class="tkxi">
        退款商品
      </div>
      <div class="flex jc-sb ai-c mt5"
           v-for="(item,index) in details.goodsList"
           v-show="item.orderItemId==$route.query.orderItemId"
           :key="index">
        <van-checkbox class="vcb"
                      v-if="details.goodsList.length > 1"
                      checked-color="#F35E3D"
                      v-model="item.checked">
        </van-checkbox>
        <div class="flex jc-sb"
             style="">
          <div class="lf">
            <img :src="item.goodsPic"
                 alt="">
          </div>
          <div class="ri">
            <div class="flex  jc-sb">
              <div class="sz">{{item.goodsName}}</div>
              <div class="my">￥{{item.goodsPrice}}</div>
            </div>
            <div class="flex ai-c jc-sb">
              <div class="sz sza">{{item.specifications}}</div>
              <div class="mya">x{{item.goodsQuantity}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="form.returnType === ''">
      <!-- 选择退款 -->

      <div class="s-tui flex ai-c jc-sb"
           @click="reasonList(0)">
        <div>
          <div class="flex ai-c">
            <img class="fh"
                 src="/img/refule/fh.png"
                 alt="">
            <div class="wytk">我要退款(无需退货)</div>
          </div>
          <div class="msdh">没收到货，或与卖家协商同意不用退货只退款</div>
        </div>
        <div>
          <van-icon name="arrow"
                    color="#C9C9C9" />
        </div>
      </div>
      <!-- 选择退货退款/退款 -->
      <div class="s-tui flex ai-c jc-sb"
            v-if="$route.query.orderStatus!=1"
           @click="reasonList(1)">
        <div>
          <div class="flex ai-c">
            <img class="fh"
                 src="/img/refule/money.png"
                 alt="">
            <div class="wytk">我要退货退款</div>
          </div>
          <div class="msdh">已收到货，需退还收到的货物</div>
        </div>
        <div>
          <van-icon name="arrow"
                    color="#C9C9C9" />
        </div>
      </div>
    </div>

    <!-- 退款信息 -->
    <div class="main"
         v-if="form.returnType !== ''">
      <div class="tkxi">
        退款信息
      </div>
      <div class="tkyy flex jc-sb ai-c"
           @click="reason=form.reason,show = true">
        <div class="yi">
          <span>退款原因</span>
          <span style="color:#F35E3A">*</span>
        </div>
        <div class="flex ai-c">
          <span class="qxz"
                v-if="form.reason==''">请选择</span>
          <span class="qxz"
                style="color:#323233"
                v-else>{{form.reason}}</span>
          <van-icon color="#C9C9C9"
                    size="10"
                    name="arrow" />
        </div>
      </div>
      <div class="flex jc-sb ai-c">
        <div class="yi">
          <span>退款金额</span>
          <span style="color:#F35E3A">*</span>
        </div>
        <div class="flex ai-c">
          <span class="qx">¥{{Number(refundAmount).toFixed(2)}}</span>
        </div>
      </div>
      <div class="ts">
        不可修改，最多¥{{Number(refundAmount).toFixed(2)}}，含发货邮费¥0.00，期中包含部分优惠
        券红包
      </div>
    </div>
    <!-- 图片 -->
    <div class="main"
         v-if="form.returnType !== ''">
      <div class="flex jc-sb">
        <span class="tkxi"
              style="margin-bottom:.2rem">补充描述和凭证</span>
        <span class="z0200">{{form.description.length}}/200</span>
      </div>
      <van-field :border="false"
                 v-model="form.description"
                 @input="inputxt"
                 rows="2"
                 style="padding:0;margin-bottom:0.3rem;"
                 autosize
                 maxlength="200"
                 type="textarea"
                 placeholder="补充描述，有助于商家更好的处理售后问题" />
      <van-uploader v-model="fileList"
                    multiple
                    :max-count="9"
                    style="">
        <template>
          <div class="preview"></div>
        </template>
      </van-uploader>
    </div>
    <div class="btnsub"
         v-if="form.returnType !== ''"
         @click="submit">
      提交
    </div>
    <van-action-sheet v-model="show"
                      style=""
                      title="退款原因">
      <ul class="uls">
        <van-radio-group v-model="reason"
                         @change="change">
          <van-cell-group>
            <van-cell v-for="(it,ix) in reasons" :key="ix" :title="it.name" clickable @click="reason = it.name">
              <template #right-icon>
                <van-radio checked-color="#F35E3D" :name="it.name" />
              </template>
            </van-cell>
          </van-cell-group>
          <!-- <li class="flex jc-sb lis"
              v-for="(it,ix) in reasons"
              :key="ix">
            <div class="dd">{{it.name}}</div>
            <van-radio checked-color="#F35E3D"
                       :name="it.name"></van-radio>
          </li> -->
        </van-radio-group>
        <div class="close"
             @click="show = false,form.reason=reason">
          确认
        </div>
      </ul>
    </van-action-sheet>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import { orderDetails, reasonList, fileUpload, returnApply } from '../../../../api'
export default {
  data() {
    return {
      checked: '',
      message: "",
      fileList: [],
      show: false,
      form: {
        description: '',
        proofPics: '',
        reason: '',
        returnType: ''   // 0, 1
      },
      details: {},
      reasons: [],
      refundAmount: 0,
      reason: '',  //选择框的退款原因
    }
  },
  created() {
    this.orderDetails()
    // this.reasonList()
  },
  methods: {
    //订单详情
    orderDetails() {
      orderDetails(
        this.$route.query.id
      ).then(res => {
        var data = res.data.data
        // data.quantity = 0
        // data.receiverPhone = this.plusXing(data.receiverPhone, 3, 4)
        // data.address = data.receiverProvince + (data.receiverProvince == data.receiverCity ? '' : data.receiverCity) + data.receiverRegion + data.receiverDetailAddress
        for (var im of data.goodsList) {
          // if (data.goodsList.length>1) {
          //   im.checked = false
          // } else {
          //   im.checked = true
          //   this.refundAmount = String(im.refundAmount)
          // }
          if (im.orderItemId == this.$route.query.orderItemId) {
            im.checked = true
            this.refundAmount = String(im.refundAmount)
          }
          var specifications = []
          im.goodsAttr = JSON.parse(im.goodsAttr)
          for (var val of im.goodsAttr) {
            specifications.push(val.v)
          }
          im.specifications = specifications.join('、')
        }
        this.details = data
      })
    },
    //退款原因表
    reasonList(val) {
      this.form.returnType = val
      reasonList(this.form.returnType).then(res => {
        console.log(res.data);
        this.reasons = res.data.data
      })
    },
    inputxt() {
      console.log(this.message)
    },
    change(e) {
      // this.form.reason = e
      // this.show = false
    },
    submit() {
      Dialog.confirm({
        message: '确定继续退款吗?',
        confirmButtonText: '不退了',
        cancelButtonText: '继续退款',
        width: '260px'
      })
        .then(() => {
          // on confirm

        })
        .catch(() => {
          // on cancel
          if (this.form.reason == '') {
            return this.$toast('请选择退款原因')
          }
          this.$toast.loading({
            duration: 0,
            forbidClick: true
          })
          var arr = []
          for (var item of this.fileList) {
            var p = new Promise((resolve, reject) => {
              fileUpload(item.file).then(res => {
                resolve(res.data.data)
              })
            })
            arr.push(p)
          }
          Promise.all(arr).then((values) => {
            console.log(values);
            console.log(values.join(','));
            this.form.proofPics = values.join(',')
            returnApply(this.$route.query.orderItemId, this.form).then(res => {
              this.$router.replace({ path: '/my/orderListSMCS/refund/success' })
            }).finally(() => {
              this.$toast.clear()
            })

          }).catch(() => {
            this.$toast.clear()
          })
        });
    }
  }
}
</script>

<style scoped>
.s-tui {
  width: 6.94rem;
  height: 1.71rem;
  background-color: #fff;
  margin: 0 auto;
  padding-right: 0.52rem;
  padding-left: 0.27rem;
  box-sizing: border-box;
  margin-top: 0.3rem;
}
.s-tui .wytk {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
}
.s-tui .msdh {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #bdbdbd;
  margin-left: 0.45rem;
  margin-top: 0.1rem;
}
.s-tui .fh {
  width: 0.35rem;
  height: 0.31rem;
  margin-right: 0.1rem;
}
.wrapper >>> .van-dialog__message {
}
.wrapper >>> .van-action-sheet__header {
  color: #353535;
  font-weight: bold;
}
.close {
  width: 6.98rem;
  height: 0.92rem;
  background: #f35e3a;
  border-radius: 1rem;
  font-size: 0.36rem;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.92rem;
  text-align: center;
  margin-bottom: 0.3rem;
}
.uls {
  padding: 0 0.3rem;
}
.dd {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: bold;
  color: #353535;
}
.lis {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 0.3rem;
  margin-bottom: 0.2rem;
}
.btnsub {
  width: 6.98rem;
  height: 0.92rem;
  line-height: 0.92rem;
  text-align: center;
  background: #f35e3a;
  border-radius: 1rem;
  font-size: 0.36rem;
  font-family: SourceHanSansCN;
  font-weight: 500;
  margin: 0 auto;
  color: #ffffff;
}
.main .preview {
  width: 1.64rem;
  height: 1.64rem;
  background-image: url(/img/refule/sctp.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.mt5 {
  margin-top: 0.3rem;
}
.wrapper {
  padding-bottom: 0.3rem;
  height: 100vh;

  /* height: 1000px; */
}
.main .my {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
  margin-left: 0.1rem;
}
.main .sz {
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: -o-ellipsis-lastline;
  font-size: 0.3rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  font-family: PingFang;
  font-weight: 500;
  color: #61626a;
  -webkit-box-orient: vertical;
  margin-bottom: 0.1rem;
}
.main .sza {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #aaaaaa;
  background: #f4f4f4;
}
.main .mya {
  font-size: 0.24rem;
  color: #aaaaaa;
  margin-left: 0.1rem;
}
.main .ri {
  width: calc(100% - 1.76rem);
}
.main .lf {
  width: 1.54rem;
  height: 1.54rem;
  background: #f4f4f4;
  border: 1px solid #eeeeee;
  border-radius: 0.01rem;
}
.main .lf img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main {
  background-color: #fff;
  padding-left: 0.3rem;
  padding-top: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.3rem;
}
.main .tkxi {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
  margin-bottom: 0.4rem;
}
.main .vcb {
  width: 1.5rem;
}
.main .tkyy {
  margin-bottom: 0.38rem;
}
.main .yi {
  font-size: 0.26rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
}
.main .qxz {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #bdbdbd;
  margin-right: 0.2rem;
}
.main .qx {
  font-size: 0.3rem;
  font-family: PingFang;
  font-weight: bold;
  color: #f35e3a;
}
.main .ts {
  margin-top: 0.1rem;
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #bdbdbd;
}
.main .z0200 {
  font-size: 0.24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #bdbdbd;
}
</style>
